



































































import Vue from "vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "MarketingHome",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  components: {
    DoceoIcon,
  },
  data: () => ({
    DOCEO_ICONS,
  }),
  created() {},
  computed: {
    doctorWidth(): string {
      return this.width + "px";
    },
    width(): number {
      var windowWidth = this.$vuetify.breakpoint.width;
      if (windowWidth > 1386) {
        return 811;
      } else if (windowWidth > 1200) {
        return 700;
      } else if (windowWidth > 1140) {
        return 600;
      } else if (windowWidth > 960) {
        return 811;
      } else if (windowWidth == 960) {
        return 811;
      } else {
        return 811;
      }
    },
    doctorHeight(): string {
      return this.width / 1.427816901408451 + "px";
    },
    isSmallScreen(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": // < 600px
          return true;
        case "sm": // 600px > < 960px
          return true;
        case "md": // 960px > < 1264px
          return false;
        case "lg": // 1264px > < 1904px
          return false;
        case "xl": //	> 1904px
          return false;
        default:
          return false;
      }
    },
  },
});
